.container {
  width: 100%;
  position: absolute;
  right: 0;
  top: -5rem;
  padding: 1.5rem;
  background-color: var(--white);
  z-index: 20;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
}

.historyContainer {
  width: 290px;
}

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: #afc0be;
}

.text {
  margin: 0.8rem 0px;
  font-size: 1.6rem;
  font-weight: 600;
}

.infoIcon {
  cursor: pointer;
}

.downIcon {
  font-size: 2rem;
  font-weight: 700;
}
