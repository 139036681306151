.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textContainer {
  flex: 70%;
}

.buttonContainer {
  flex: 30%;
  display: flex;
  justify-content: flex-end;
}
