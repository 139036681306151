.mainContent {
  padding: 1rem;
}

.questionContainer {
  margin-bottom: 3rem;
}

.descriptionContainer {
  padding: 3rem;
  margin-bottom: 4rem;
  background-color: var(--dark-white);
  border-radius: var(--border-radius);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

/* .buttonsContainer > * {
  flex: 50%;
} */

.container {
  width: 70%;
  display: flex;
  justify-content: center;
  position: relative;
}

.sigleButtonContainer {
  flex: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.sigleButtonContainer button {
  width: 100%;
}

.sigleButtonContainer.lastChild {
  margin-left: 3rem;
}

.declineButton {
  margin-top: 2rem;
  text-decoration: none;
  color: #db7070;
}

.anotherAssigendUser {
  color: #afc0be;
  font-size: 1.2rem;
  font-weight: 700;
}

.logoContainer {
  margin-top: 10rem;
}

.note {
  margin-top: 1rem;
}

.errorAndCounterContainer {
  display: flex;
  justify-content: space-between;
}
