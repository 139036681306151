.bottomText {
  color: RGBA(11, 36, 33, 0.7);
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.secondHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
