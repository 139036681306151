.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 4rem 0px;
}

@media screen and (max-width: 1224px) {
  .container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1124px) {
  .container {
    max-width: 950px;
  }
}
