.container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
}

.formContainer {
  width: 270px;
}

.responseEmailContainer {
  width: 270px;
}

.formContainer .formInsideContainer {
  width: 100% !important;
}

.containerWithBorder {
  margin-top: -1rem;
  padding: 1rem 2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-color);
  width: 26rem;
  position: relative;
}

/* .containerWithoutBorder {
  padding: 1rem 2rem;
} */

.containerWithBorder.error {
  border: 1px solid var(--third-color);
}

.title {
  color: var(--text-color5);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}

.subtitle {
  font-weight: 600;
  cursor: pointer;
  position: relative;
  word-wrap: break-word;
}

.input {
  width: 100%;
  margin-top: 0.3rem;
  border: none;
  outline: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: inherit;
}

.iconContainer {
  position: absolute;
  right: 1rem;
  top: 2.3rem;
}

.icon {
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 50%;
  opacity: 1;
}

.icon.error {
  background-color: var(--third-color);
  opacity: 1;
}

.icon.success {
  background-color: var(--primary-color);
  opacity: 1;
}

p.assigned {
  color: var(--primary-color);
}

p.declined {
  color: var(--third-color);
}

p.ready {
  color: RGB(255, 171, 102);
}

.infoIcon {
  margin-left: 2rem;
  cursor: pointer;
}

.lastChild {
  position: relative;
}

.errorMessage {
  color: var(--third-color);
  /* margin-top: 5px; */
  font-size: 1.2rem;
}
