.cardContainer {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30rem;
}

.heading {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.167;
  color: var(--text-color5);
  margin-bottom: 0.8rem;
}

.container {
  border-radius: 2rem;
  height: 4rem;
  border: 1px solid var(--primary-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.activeContainer {
  justify-content: flex-start;
  width: 30rem;
}

.containerText {
  padding-left: 10px;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-color4);
  z-index: 5;
}

.backgroundStatus {
  position: absolute;
  background: var(--button-color);
  height: 100%;
  border-radius: inherit;
}
