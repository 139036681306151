.questionCardContainer {
  width: 100%;
  border-radius: var(--border-radius);
  border: 1px solid var(--questionnair-border);
  margin-top: 4rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container > *:first-child {
  margin-right: 2rem;
}

.questionTextContainer {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
}

.questionTextContainer2 {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
}

.questionTextContainer:first-child {
  border-bottom: 1px solid var(--black2);
}

.questionTextContainer > *:first-child {
  flex: 70%;
}

.questionTextContainer > *:last-child {
  flex: 30%;
}

.questionHeadingContainer {
  display: flex;
  align-items: center;
  /* justify-content:flex-start !important; */
  margin-bottom: 1.8rem;
}

.heading2 {
  /* flex: 50%; */
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.429;
  margin-right: 2rem;
}

.heading2::first-letter {
  text-transform: uppercase;
}

.questionHeadingContainer span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--gray-green);
}

.bottomTextContainer {
  display: flex;
}

.bottomTextContainer h4:first-child {
  margin-right: 6.4rem;
}

.questionnaireStats {
  margin-top: 2rem;
}

.statsContainer {
  /* width: 100%; */
  display: inline-flex;
  justify-content: space-between;
}

.statsContainer > * {
  margin-right: 3rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.statsContainer > *:first-child {
  list-style: none;
}

.completedQuestionStats {
  color: #32ccbc;
}

.readyForReviewQuestionStats {
  color: #ffab66;
}

.awaitingActionStats {
  color: #e56666;
}

.statsContainer li::before {
  color: red;
  font-weight: bold;
}

.secondHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resendEmailButton {
  text-decoration: underline;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: var(--text-color3);
  font-weight: 600;
  vertical-align: sub;
}

.generateFileButton {
  margin-left: 1rem;
  padding: 10px 20px;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  background: var(--button-color);
  border-radius: var(--border-radius);
  cursor: pointer;
}
