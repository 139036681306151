.container{
    width: 100%;
    border-top: var(--border);
    border-bottom: var(--border);
    padding: 1rem 3rem;
    background-color:var(--section-question-form-background-color);
}

.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formContainer >*{
    margin: 1rem 0px;
}


.buttonsContainer{
    align-self: flex-end;
    display: flex;
    width: 50rem;
}

.buttonsContainer>*:not(:last-child){
    margin-right: 3rem;
}