.container {
  /* position: absolute; */
  /* top: 0;
    right: 15rem; */
  width: 50%;
  background-color: var(--white);
  width: 70rem;
  display: flex;
  align-items: center;
  height: 10rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
}

.formContainer {
  width: 80%;
  display: flex;
  /* align-items: center; */
}

.formContainer > * {
  flex: 50%;
}

.formContainer > *:first-child {
  margin-right: 1rem;
}

.buttonContainer {
  display: flex;
}

.buttonContainer > *:not(:last-child) {
  margin-right: 1rem;
}

.textContainer {
  bottom: -9rem;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .textContainer button {
  width: 100%;
} */

.assignForm {
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
  background-color: white;
  width: 75%;
  padding: 1rem;
}

.groupForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.groupForm > *:first-child {
  flex: 70%;
  margin-right: 1rem;
  height: inherit !important;
}

.groupForm > *:last-child {
  flex: 30%;
}

.errorMessage {
  color: var(--third-color);
  font-size: 1.5rem;
}
