.main{
    width: 100%;
}

.container{
    position: relative;
    width: 100%;
    height: 6rem;
    /* margin:1rem; */
}


.inputText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    font-family: inherit;
    font-size: inherit;
    outline: none;
    padding-left: 1.8rem ;
    padding-top: 2rem;
    background: none;
    transition: border 100ms ease-in-out;
    font-size: 1.8rem;
}

.error .inputText{
    border-color: var(--third-color);
}

.warning .inputText{
    border-color: var(--lite-orange);
}


.success .inputText{
    border-color: var(--primary-color);
}

.inputText:focus, .inputText:hover {
    border-width: 2px;
    outline: none;
}

.floatingLabel {
    position: absolute;
    pointer-events: none;
    left: 2rem;
    top: 1.8rem;
    transition: 0.2s ease all;
    font-size: 18px;
    font-weight: 500;
}

.inputText:focus ~ .floatingLabel,
.inputText:not(:focus):valid ~ .floatingLabel{
    top: 8px;
    bottom: 10px;
    left: 22px;
    font-size: 12px;  
    opacity: 1;
    color: var(--gray);

}

.inputText:not(:focus):valid{
    border-width: 2px;
}

.iconContainer{
    position:absolute;
    right: 1rem;
    top: 2.3rem;
}

.icon{
    height: 20px;
    width: 20px;
    padding:2px;
    border-radius:50%;
    opacity: 0;
}

.icon.error{
    background-color: var(--third-color);
    opacity: 1;
}

.icon.success{
    background-color: var(--primary-color);
    opacity: 1;
}

.icon.warning{
    background-color: var(--lite-orange);
    opacity: 1;
}

.errorMessage{
    color: var(--third-color);
    margin-top: 5px;
    font-size: 1.5rem;
}