.button {
  background: none;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  color: #5e7f7c;
  font-size: 1.6rem;
  font-weight: 700;
}

.disableButton {
  color: #afc0be;
}

.container {
  display: flex;
}

.container > *:first-child {
  margin-right: 4rem;
}
