.modaloverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: var(--black);
  opacity: 0.7;
  z-index: 500;
}

.confirmationModal,
.resendEmailModal,
.modal {
  border-radius: 1rem;
  padding: 1rem;
  position: fixed;
  z-index: 1000;
  /* max-height: calc(100% - 200px); */
  /* top: 75px; */
  left: calc(100% - 50%);
  transform: translate(-50%, 10%);
  display: flex;
  flex-direction: column;
}

.modal {
  zoom: 75%;
  width: 777px;
}

.confirmationModal,
.resendEmailModal {
  width: 600px;
  transform: translate(-50%, 50%);
}

.resendEmailModal {
  transform: translate(-50%, 20%);
}

.differeceResponseModel {
  width: 850px;
  transform: translate(-50%, 10%);
}

.modalContainer {
  width: 100%;
}

/* .buttonsContainer{
    margin-top: 2rem;
    align-self: flex-end;
    display: flex;
    width: 50rem;
}

.buttonsContainer>*:not(:last-child){
    margin-right: 3rem;
} */

.heading1 {
  margin: 0px;
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--primary-color);
}

.buttonsContainer {
  margin-top: 2rem;
  /* align-self: flex-end; */
  display: flex;
  width: 50rem;
}

.buttonsContainer > * {
  flex: 50%;
}

.buttonsContainer > *:not(:last-child) {
  margin-right: 1.5rem;
}
