.card{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:var(--white);
    box-shadow: 8px 8px 30px 0px rgba(0,0,0,0.08);
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
}

