.container {
  margin-top: 4rem;
  min-height: 20rem;
  background-color: rgba(50, 204, 188, 0.1);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container h3 {
  /* max-width: 30rem; */
  font-weight: 500;
  margin-bottom: 1.5rem;
}
