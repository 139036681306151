.container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.dateCard {
  width: 8.2rem;
  height: 10.5rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--date-card-color);
  background-color: var(--white3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dateCard h6 {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  background-color: var(--black3);
}

.dateCardText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dateCardText p:first-child {
  font-size: 3.2rem;
  font-weight: 300;
}

.dateCardText p:last-child {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 400;
}
