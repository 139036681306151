.questionCardContainer {
  width: 100%;
  border-radius: var(--border-radius);
  margin-top: 4rem;
  /* background-color: var(--white); */
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow1);
}

.singleQuestionCardContainer {
  background-color: rgb(216 216 216 / 0.3);
  width: 100%;
  border-radius: var(--border-radius);
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow1);
}

.question {
  background-color: rgb(216 216 216 / 0.3);
  width: 100%;
  border-top: var(--border);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow1);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerContainer {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: var(--white);
}

.secondHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* .headerContainer > *:first-child {
  flex: 70%;
}

.headerContainer > *:last-child {
  flex: 30%;
} */

.sectionCountText {
  color: var(--text-color5);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}

.heading2 {
  text-transform: capitalize;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.429;
  margin-right: 2rem;
}

.buttonsContainer {
  display: flex;
  justify-content: space-around;
}

.buttonsContainer > * {
  margin-left: 4rem;
}

.buttonContainer {
  background-color: var(--white);
  border-top: var(--border);
  padding: 2rem 3rem;
}
