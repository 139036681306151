.container{
    margin-top: 2rem;
}

.questionnaireList{
    display: flex;
    flex-direction: column;
}

.questionnaireList >*{
    margin: 2rem 0px;
}

