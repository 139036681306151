.formContainer {
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.container {
  margin: 2rem 0px;
}

.formContainer textarea {
  border: none;
  background: none;
  width: 100%;
  padding: 1.5rem;
  font-size: 1.8rem;
  outline: none;
}

.dragContainer {
  background-color: #f2f8f8;
  font-size: 2rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.errorMessage {
  color: var(--third-color);
  margin-top: 5px;
  font-size: 1.5rem;
}
