.cardContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
    height: 12rem;
    background-color:var(--white);
    border-radius:var(--border-radius);
    box-shadow:var(--box-shadow1);
}

.cardContainer >*:first-child{
    margin-right: 2rem;
}