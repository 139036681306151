.button {
  align-items: center;
  border-radius: 10px;
  border: none;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.1);
  color: rgb(75, 74, 74);
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 7.2rem;
  letter-spacing: 1px;
  outline: none;
  overflow: hidden;
  padding: 0px;
  transition: all 0.2s;
  width: 330px;
  text-transform: uppercase;
  cursor: pointer;
}

.viewButton {
  width: 150px;
  height: 6.2rem;
}
.button > * {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  flex: 20%;
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-size: 2rem;
  font-weight: 900;
}

.withBackground {
  background: var(--button-color);
}

.pauseAndEditButton {
  background: var(--button-color2);
}

.noBackground {
  background-color: RGB(237, 249, 248);
  border: 1px solid var(--primary-color);
}

.buttonText {
  flex: 80%;
}

.button:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}
