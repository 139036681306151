.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  /* height: 100vh; */
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0px 4rem 0px;
}

.logoContainer > *:last-child {
  display: flex;
  align-items: center;
  /* have to add font popins */
}

.icon {
  margin-left: 0.7rem;
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 50%;
  opacity: 1;
  background-color: var(--primary-color);
}
