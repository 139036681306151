.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.firstChild {
  display: flex;
  justify-content: space-between;
}

.middleChild {
  margin: 2rem 0px;
}

.lastChild {
  display: flex;
  justify-content: center;
}
