.signup {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.headingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.heading1 {
  margin: 0px;
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--primary-color);
}

.headingContainer > *:last-child {
  color: var(--text-color2);
  font-size: 1.8rem;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container > * {
  margin: 1rem 0;
}

.link {
  font-size: 2rem;
  font-weight: 600;
}
