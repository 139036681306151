.assignSectionQuestionContainer {
  padding: 2rem 3rem;
  /* border-top: var(--border); */
  display: flex;
  width: 100%;
  background-color: var(--white);
  justify-content: space-between;
}

.responseQuestionContainer {
  padding: 2rem;
  width: 100%;
}

.questionTitlesContainer {
  flex: 45%;
}

.questionTitlesContainer h5 {
  padding-right: 4rem;
}

.summaryContainer {
  flex: 55%;
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
}

/* .summaryContainer > * {
  flex: 33%;
} */

.showResponseButton {
  cursor: pointer;
}

.open {
  background-color: #f4fdfc;
}

.responseContainer {
  background-color: var(--white);
}

.open + .responseContainer {
  margin-left: 1rem;
}

.responseContainer > *:nth-of-type(even) {
  background-color: #f4fdfc;
}

.moreInfoForm {
  background-color: #f4fdfc;
  border-width: 80%;
  border-top: var(--border);
  transition: all 0.5ms ease-in;
}

.formContainer {
  padding: 0px 2rem;
}

.signleResponseContainer {
  display: flex;
  justify-content: space-between;
}

.responseText {
  flex: 80%;
}

.responseFileName {
  flex: 20%;
  margin-left: 2rem;
}

.heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: #afc0be;
}

.acceptOrMoreInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #edf9f8;
  border-top: var(--border);
}

.moreInfoButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem; */
  background-color: #edf9f8;
}

.containerText {
  flex: 40%;
}

.buttonsContainer {
  flex: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.box {
  width: 40px;
  height: 50px;
  margin-left: 2rem;
  border-left: var(--border);
  border-bottom: var(--border);
  /* border-radius: 0px var(--border-radius); */
}

.questionResponseContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
