.button {
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: transparent;
}

.deleteButton {
  color: var(--third-color);
}

.viewButton {
  width: 15rem;
  background: var(--button-color);
  border-bottom-right-radius: var(--border-radius);
}

.viewWhiteButton {
  width: 15rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-right-radius: var(--border-radius);
}
