.formField {
  margin: 0.5rem 0px;
  width: 100%;
  padding: 2rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  outline: none;
  font-size: 2rem;
  color: var(--text-color3);
}

.formField::placeholder {
  padding-top: 0.5rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.formField:focus,
formField:hover {
  border-width: 2px;
}

textarea.formField {
  height: 14rem;
}

.errorMessage {
  color: var(--third-color);
  margin-top: 5px;
  font-size: 1.5rem;
}

.error {
  border-color: var(--third-color);
}

.success {
  border-color: var(--primary-color);
}

.group {
  margin-top: 1rem;
}

.label {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color3);
}

.title {
  margin: 1.5rem 0px 1rem 0px;
}

.checkboxContainer {
  margin: 0px 0px 1.5rem 0px;
}

.checkbox {
  -webkit-appearance: none;
  width: 2rem;
  height: 2rem;
  /* border: 1px solid #d9d9d9; */
  border: 1px solid var(--text-color3);
  border-radius: 1px;
  vertical-align: sub;
  outline: none;
  position: relative;
}

.checkbox:hover,
.checkbox:focus,
.checkbox:checked {
  border: 1px solid var(--primary-color);
}

.checkbox:checked {
  background-color: rgba(225, 245, 243, 0.6);
}

.checkbox:checked::after {
  content: "✓";
  font-size: 1.4rem;
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  color: var(--primary-color);
  position: absolute;
  left: 0;
  top: 0px;
  transform: translate(17%, 5%);
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==); */
  /* background-size: 40px;
  background-repeat: no-repeat; */
  background-position: center;
}

.radioInput {
  margin-right: 1rem;
}
