.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 9.5rem;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow2);
  z-index: 100;
}

.navbar + div {
  padding-top: 15rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer > *:last-child {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.icon {
  margin-left: 0.7rem;
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 50%;
  opacity: 1;
  background-color: var(--primary-color);
}

@media screen and (max-width: 1224px) {
  .container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1124px) {
  .container {
    max-width: 950px;
  }
}
