.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.main > *:first-child {
  /* margin-top: 2rem; */
  zoom: 75%;
}

.container {
  /* margin-top: 8rem; */
  width: 54rem;
  display: flex;
  flex-direction: column;
  zoom: 75%;
}
