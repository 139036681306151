@import url("./Normalize.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300&family=Poppins:wght@400;500&display=swap");

:root {
  --primary-color: #25dac7;
  --second-color: #2b5f5a;
  --third-color: #e56666;
  --button-hover-color: #29c5b6;
  --lite-pink1: #fcefef;
  --lite-pink2: #fff6ef;
  --lite-orange: #ffab66;
  --lite-sky1: #2b5f5a;
  --lite-sky2: #2b5f5a;
  --gray: RGBA(47, 47, 47, 0.3);
  --box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow: 10px 10px 19px -200px rgba(0,0,0,0);
-moz-box-shadow: 10px 10px 19px -200px rgba(0,0,0,0); */
  --button-color: linear-gradient(180deg, #90f7ec 0%, #6ae5d8 100%);
  --button-color2: linear-gradient(to top, #ff8080, #ffa3a3);
  --button-text-color: rgba(11, 36, 33, 0.7);
  --text-color1: #2f2f2f;
  --text-color2: rgba(47, 47, 47, 0.5);
  --text-color3: rgb(47, 47, 47);
  --text-color4: rgba(0, 0, 0, 0.8);
  --text-color5: rgb(175, 192, 190);
  --border-radius: 8px;
  --border: 1px solid var(--black2);
  --black: #000000;
  --black2: RGBA(0, 0, 0, 0.25);
  --black3: RGBA(0, 0, 0, 0.05);
  --black4: rgba(0, 0, 0, 0.08);
  --black5: rgba(0, 0, 0, 0.3);
  --box-shadow1: 8px 8px 30px 0px var(--black4);
  --box-shadow2: 0px 0px 20px 0px var(--black5);
  --white: rgb(255, 255, 255);
  --dark-white: RGB(242, 248, 248);
  --white3: RGB(225, 245, 243);
  --date-card-color: RGB(50, 204, 188);
  --gray-green: RGBA(11, 36, 33, 0.7);
  --questionnair-button-card-background-color: rgb(205, 245, 241);
  --questionnair-border: RGB(155, 217, 211);
  --section-question-form-background-color: RGBA(233, 251, 249, 0.5);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html {
  font-size: 10px; /* 62.5% of 16px = 10px */
  line-height: 1.5;
}

body {
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  /* background-color:linear-gradient(180deg, rgba(255,255,255, 1) 0%, rgba(225, 245, 243, 0.5) 100%); */
  /* background-color:rgba(248, 248, 248, 0.5) ; */
  background: linear-gradient(
    180deg,
    var(--white) 0%,
    rgba(225, 245, 243, 0.5) 50%
  );
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  white-space: pre-wrap;
}

a {
  font-size: 2rem;
  color: var(--text-color3);
}

button {
  color: var(--button-text-color);
}

.heading1 {
  margin: 0px;
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--primary-color);
}

.heading2 {
  font-weight: 300;
  font-style: 3rem;
  line-height: 1.8;
}

.heading3 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.833;
  color: var(--black);
}

.heading4 {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 1.5;
}

.heading5 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--text-color3);
}

.heading5-black {
  font-size: 2rem;
  font-weight: 400;
  color: var(--black);
}

.button-lite {
  font-size: 18px;
}

.heading-lite {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.833;
  color: var(--black);
}

.bold-heading {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
}

.text-normal {
  font-size: 2rem;
  font-weight: 400;
  color: black;
}

.text-normal2 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color3);
}

.text-normal--bold {
  font-weight: 600;
}

.text-sm1 {
  font-size: 1.8rem;
  font-weight: 400;
}

.text--bold,
.text-normal--bold,
a,
.text-sm1--bold {
  font-weight: 600;
}

.text-sm2 {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text-color3);
  white-space: pre-wrap;
}

.text-sm2-bold {
  font-weight: 700;
  color: #5e7f7c;
}

.text-sm3 {
  font-size: 1.4rem;
  color: var(--text-color3);
}

.question-title {
  font-size: 2.8rem;
}

.question-title:first-letter {
  text-transform: uppercase;
}

.error {
  align-self: start;
  color: var(--third-color);
  font-size: 1.5rem;
}
.success {
  align-self: start;
  color: var(--primary-color);
  font-size: 1.5rem;
}

.capitallize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.m-10 {
  margin-top: 1rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

/* @media screen and (max-width:1000px) {
  html{
    font-size:50.5%; 
    line-height:1.5;
  }
}



@media screen and (max-width:700px) {
  html{
    font-size:45.5%; 
    line-height:1.5;
  }
} */
