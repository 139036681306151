.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container > * {
  margin: 1rem 0px 0px 0px;
  width: 100%;
}

.container > *:first-child {
  align-self: flex-start;
}

.heading5 {
  font-size: 2rem;
  font-weight: 500;
}

.loginText {
  margin-top: 4rem;
  padding: 1rem;
  font-size: 1.8rem;
}

.loginText > * {
  margin: 1rem 0px;
}

.loginText h5 {
  font-weight: bold;
  font-size: inherit;
}
