.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--questionnair-button-card-background-color);
  height: 6rem;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.firstButtonContainer {
  height: inherit;
  width: 30rem;
  display: flex;
  justify-content: space-around;
}
