.modalForm{
    padding: 2rem 0px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formContainer >*{
    margin: 1rem 0px;
}

.buttonsContainer{
    display: flex;
}

.buttonsContainer>*:not(:last-child){
    margin-right: 1.5rem;
}