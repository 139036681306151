.button{
    border-radius:var(--border-radius) ;
    
    border: none;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    color: var(--button-text-color);
    font-family: inherit;
    font-size: 2.2rem;
    font-weight: bold;
    height:7.2rem;
    outline: none;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

.withBackground{
    background:var(--button-color); 
}

.noBackground{
    background-color: RGB(237, 249, 248);
    border:1px solid var(--primary-color) ;
}


.button:hover{
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}