

.formField{
    margin: 0.5rem 0px;
    width: 100%;
    padding: 2rem 1.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    outline: none;
    font-size: 2rem;
    color: var(--text-color3);
}

.formField::placeholder{
    padding-top: 0.5rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.formField:focus, formField:hover{
    border-width: 2px;
}

.inputContainer{
    display: flex;
    align-items: center;

}

.inputContainer>*{
    flex: 50%;
}

.inputContainer input{
    margin-right: 1rem;
}