.container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.container > *:first-child {
  flex: 70%;
}

.container > * .status {
  flex: 30%;
  padding-left: 2rem;
}


.editButtton{
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--gray-green);
  cursor: pointer;
}
