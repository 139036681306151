.sectionQuestionContainer {
  /* border-top: var(--border); */
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}

.sectionTextContainer h5 {
  margin-bottom: 1.2rem;
}

.buttonContainer {
  padding: 2rem 0px;
  width: 30rem;
  display: flex;
  justify-content: space-around;
  background-color: var(--white);
}
