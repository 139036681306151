.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  min-height: 18rem;
  border-radius: var(--border-radius);
}

.draftQuestionContainer {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading2 {
  flex: 50%;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.429;
  margin-top: 1.4rem;
}

.cardContainer {
  flex: 50%;
  display: flex;
  justify-content: space-between;
}

.completedDateContainer {
  width: 100%;
  padding: 0px 2rem;
}

.assignContainer {
  background-color: var(--white);
  box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius);
  margin: 3rem 0px;
}

.assignContainer .cardContainer {
  flex: 20%;
  justify-content: space-between;
}

/* .assignContainer .cardContainer > *:first-child {
  margin-right: 10rem;
} */
